import * as React from 'react'

import { Helmet } from 'react-helmet'

const NotFoundPage = () => (
    <main>
        <Helmet title="Page not found | Rens Vleeming" defer={false} />
        <h1>Page not found</h1>
    </main>
)

export default NotFoundPage
